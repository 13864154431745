#sidebar {
    height: 100vh;
    max-width: 255px; 
    background-color: #0C2B3B !important;  
}
.sticky-top {
        position: sticky;
        top: 0;
        z-index: 1051;
    }
    .col-sm-2half {
        position: relative;
        min-height: 1px;
        padding-right: 15px;
    }
    
    @media (min-width: 768px) {
        .col-sm-2half{
            float: left;
        }
        .col-sm-2half {
            width: 22%;
        }
    }
    
.sidebar__logo {
        display: grid;
        place-items: center;
        height: 90px;
        font-size: 1.5rem;
        font-weight: 700;
        font-family: 'Mochiy Pop P one', sans-serif;
        border-bottom: 1px solid #46E7AD;

}
.Rsidebar__logo {
  width: 130px;
  height: 38px;
}
.sidebar__menu {
        position: relative;
}
.sidebar__menu__item {
            display: flex;
            align-items: center;
            place-content: flex-start;
            padding: 1rem 3.5rem;
            font-size: 14px;
            margin-bottom: 10px;
            font-weight: 300;
            color:  #fff;
            transition: color 0.3s ease-in-out;
            text-decoration: none;
            min-height: 57px;
}
.sidebar__menu a {
    text-decoration: none !important;
}
.sidebar__menu__item.active {
        color: #fff;
        width: calc(100% - 0);
        border-radius: 10px;
        background: linear-gradient(180deg, #2BC38C 0%, #27A97A 100%);
        z-index: -1;
        /* transform: translateX(-50%);
            transition: 0.3s ease-in-out; */
}
.sidebar__menu__item__icon {
        margin-right: 1rem;
}
.sidebar__menu__item__icon img {
        font-size: 1.75rem;
}
.sidebar__menu__indicator {
            position: absolute;
            top: 0;
            left: 50%;
            width: calc(100% - 40px);
            border-radius: 10px;
            background: linear-gradient(180deg, #2BC38C 0%, #27A97A 100%);
            z-index: -1;
            transform: translateX(-50%);
            transition: 0.3s ease-in-out;
}
.Icon {
    height: 20px;
    width: 20px;
    display: block;
}
.active_{
        width: calc(100% - 0);
            border-radius: 10px;
            background: linear-gradient(180deg, #2BC38C 0%, #27A97A 100%);
            z-index: -1;
            color:white;
            /* transform: translateY(0%);
            transition: 0.3s ease-in-out; */
}

/* TOP NAVIGATION BAR */
#topnav.navbar {
    border-bottom: none !important;
    padding-left: 22%;
    padding-right: 5%;
    height: 76px;
    z-index: 1050;
    width: 100%;
  }
  .navbar-light {
    background-color: #FFFFFF !important;
    border: none !important;
    border-width:0!important;
}
  .grey-color{
    color: gray;
  }
  .navbar-nav{
  align-items: center;
  }
  #responsive-navbar-nav a{
    color: black;
  }
  .roundedCircle{
    vertical-align: middle;
    width: 40px;
    height: 40px;
    padding: 2px;
    border: 1px solid #DFE0EB;
    border-radius: 50%;
  }
  .AdminroundedCircle {
    vertical-align: middle;
    width: 112px;
    height: 112px;
    padding: 2px;
    border: 1px solid #DFE0EB;
    border-radius: 50%;
}
  .dropdown-toggle::after {
    content: none;
  }
  
  /* SIDEBAR NAVIGATION */
  /* .sidebar {
    padding: 0px 20px;
  } */
    
  
  /* MAIN CONTENTS */
  .contents{
        margin-top:80px;
        padding-right: 5%;
  }
  @media screen and (max-width: 1024px) {    
    .sidebar__menu__item {
      padding: 1rem 2.5rem !important;
  }
    }

  @media screen and (max-width: 912px) {    
  .sidebar__menu__item {
    padding: 0.5rem !important;
    min-height: 47px !important;
  }
  }