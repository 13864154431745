@import url('https://fonts.googleapis.com/css?family=Fira+Sans:400,500,600,700,800');
* {
  box-sizing: border-box;
}
body {
  margin: 0;
  background: #f9fbfd !important;
  min-height: 100vh;
  display: flex;
  font-weight: 300;
  font-family: 'Inter';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
h1,h2,h3,h4,h5,h6,label,span {
  font-weight: 400;
  font-family: "Inter";
  line-height: 27px !important;
}
body, html, .App, #root {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}
.fw-normal{
  font-style: normal;
}
.fw-bold{
  font-weight: bold;
}
.Auth-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.loginpage{
  background-color: white;
  display: flex;
  justify-content: center;
  text-align: left;
  width:100% ;
  height:100% ;
}
.login_left{
  background-position: center;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  color: white;
  overflow: hidden;
  /* background: #0F1F26;  fallback for old browsers */
  background-image: linear-gradient(0deg, rgba(15, 31, 38, 0.95),  rgba(15, 31, 38, 0.95)), url("public/images/Rloginbg.svg");
}
.login_left--contents{
  width: 80%;
  margin: auto;
  padding: 40px 55px 45px 55px;
}
.R-logo{
  width:270px;
  height:78px;
}
.login_left--title{
  font-size: 16px;
  color: #FFFFFF;
  text-align: left;
}
.login_right--form{
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;
  width: 75%;
}
.login_right--form--contents{
  width: 100%;
  margin: auto;
  background: #ffffff;
  padding: 40px 55px 45px 55px;
}
.login_right--form-title{
  color:  #95aac9;
}
.login_right--btn-login{
  background: linear-gradient(180deg, #2BC38C 0%, #27A97A 100%);
  border-radius: 8px;
  border: none;
  display: block;
  margin: 10px 0;
  padding: 10px;
  width: 100%;
  color: #FFFFFF;
}
.login_right--forgot-password{
  color: #BCBCBC;
  font-size: 14px;
  text-align: center;
}
.login_right--forgot-password a{
  color: #2BC38C;
  text-decoration: none;
}
/* Use a media query to add a breakpoint at 800px: */
@media screen and (max-width: 768px) {
  .login_left{
    display: none;
  }
  .login_right{
    width: 100%;
  }
  .login_right--form--contents {
    width: 100%;
  }
}
@media screen and (max-width: 480px) {
  .login_left{
    display: none;
  }
  .login_right{
    width: 100%;
  }
  .login_right--form--contents {
    width: 100%;
  }
  .fw-normal {
    font-style: normal;
    font-size: 12px;
  }
  .login_right--form--contents {
    width: 100%;
    margin: auto;
    background: #ffffff;
    padding: 20px 30px 25px 30px;
  }
}

/* STAFF EMPTY STATE */
.breadcrumb-item {
  cursor: pointer !important;
}
.breadcrumb-item.active {
  color: #2BC38C;
}
.breadcrumb-item a {
  color: #0F1F26;
  cursor: pointer;
  text-decoration: none;
}
.breadcrumb-item + .breadcrumb-item:before {
  align-self: center;
  color: #d2ddec;
  content: "\3E";
}
.header-body {
  padding-bottom: 1.5rem;
  padding-top: 1.5rem;
}
.header-pretitle {
  color: #6E84A3;
  font-weight: 300 !important;
  font-size: .7rem;
  letter-spacing: 0.03em;
  text-transform: capitalize;
  text-align: left;
}
.header-title {
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 700;
  text-align: left;
  color: #000000;
}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  background-color: transparent;
  border-color: transparent transparent #2BC38C;
  color: #2BC38C;
}
.nav-tabs .nav-link:not(.active) {
  color: #95aac9;
}
.nav-tabs .nav-link {
  background: none;
  border: 1px solid transparent;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  margin-bottom: -1px;
  min-width: 50px;
  text-align: center;
  text-decoration: none;
}
.nav-tabs .nav-link {
  border-bottom: 1px solid transparent;
  border-left-width: 0;
  border-right-width: 0;
  border-top-width: 0;
  padding: 1rem;
}
.btn-primary {
  background: linear-gradient(180deg, #00b17a 0%, #27A97A 100%) !important;
  border-color: #00b17a !important;
  color: #fff !important;
  border-radius: 6px;
  min-height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 16px;
  padding-left: 16px;
  font-weight: 300;
  font-size: 0.9rem;
  min-width: 180px;
  box-shadow: none !important;

}
.lift {
  transition: box-shadow 0.25s ease, transform 0.25s ease;
} 
.card {
  background-color: #fff;
  word-wrap: break-word;
  background-clip: border-box;
  background-color: #fff;
  border: 1px solid #edf2f9;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  min-width: 0;
  position: relative;
  border-color: #edf2f9;
  margin-bottom: 1.5rem;
}
.page-empty .card-body {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 60%;
  margin: 0 auto;
  height: 100%;
}
.card-body .text-muted {
  color: #BCBCBC !important;
  font-size: 0.8rem;
  font-weight: 300;
}
.card-header {
  align-items: center;
  display: flex;
  flex-direction: row;
  height: 60px;
  padding-bottom: 0.75rem;
  padding-top: 0.75rem;
  background-color: transparent;
  border: none;
}
.card-header-title {
  margin-bottom: 0;
  font-weight: 300;
  font-size: 16px;
}
.card-header > :first-child {
  flex: 1 1;
}
.btn-outline-primary {
  border-color: #2BC38C !important;
  background-color: transparent !important;
  color: #0F1F26 !important;
  border-radius: 6px !important;
  min-width: 150px;
  font-weight: 300;
  font-size: 0.7rem !important;
  min-height: 35px;
  display: flex;
  justify-content: center;
  align-items: center !important;
  padding-top: 3px !important;
  box-shadow: none !important;
}
.btn-sm{
  line-height: 1.75;
  border-radius: 0.25rem;
  font-size: 0.8125rem;
  padding: 0.125rem 0.5rem;
  background-color: #FFFFFF;
}
.form-label {
  font-size: 0.8rem;
  margin-bottom: 0.5rem;
  color: #0F1F26;
  font-weight: 300;
  display: flex;
}
.form-control{
  appearance: auto;
  background-clip: padding-box;
  background-color: transparent !important;
  background-position: right 0.75rem center;
  background-repeat: no-repeat;
  background-size: 0.75em 0.75em;
  border: 1px solid  #ced4da;
  border-radius: 0.375rem;
  color: #BCBCBC !important;
  display: block;
  font-size: 0.8375rem;
  font-weight: 300;
  line-height: 1.5;
  padding: 0.65rem 0.95rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  width: 100%;
  box-shadow: none !important;
}
.form-control:hover {
  border: 1px solid #2BC38C !important;
}
.form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: #2BC38C;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(30, 234, 12, 0.25);
}

/* CREATE STAFF FORM */
.form-select {
  display: block;
  width: 100%;
  padding: 0.65rem 0.95rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 13px;
  font-weight: 400;
  line-height: 1.5;
  color: #BCBCBC !important;
  background-color: #fff;
  background-image: none;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: 0.375rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  appearance: none;
  box-shadow: none !important;
}
.form-select:hover {
  border: 1px solid #2BC38C !important;
}
.form-select:focus {
  color: #212529;
  background-color: #fff;
  border-color: #2BC38C;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(30, 234, 12, 0.25);
}
.table thead th {
  background-color: #f9fbfd;
  color: #95aac9;
  font-size: 0.625rem;
  font-weight: 600;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  padding-left: 1.5rem !important;
  padding: 1rem;
  vertical-align: top;
  text-align: left;
}
.table tbody{
  background-color: #fff;
}
.table tbody td {
  background-color: #fff;
  font-size: 0.8125rem;
  letter-spacing: 0.08em;
  padding-left: 1.5rem !important;
  padding: 1rem;
  text-align: left;
}
.table-stat {
  display: inline-block;
  margin-right: 12px;
  margin-left: 12px;
  color: #BCBCBC;
  font-weight: 300;
  background-color: #fff;
}
.dot {
  display: inline-block;
  border-radius: 50%;
  height: 7px;
  width: 7px;
  background-color: #46E7AD;
  margin-right: 0.5rem;
  text-transform: lowercase !important;
}
.dotinactive {
  background-color: #BCBCBC;
  display: inline-block;
  border-radius: 50%;
  height: 7px;
  width: 7px;
  margin-right: 0.5rem;
  text-transform: lowercase !important;
}
.bi-three-dots{
  cursor: pointer; 
  background: transparent !important;
  color: #858585;
  font-size: 16px;
}
.dropdown-item {
  background-color: transparent;
  border: 0;
  clear: both;
  color: #6e84a3;
  display: block;
  font-weight: 400;
  padding: 0.375rem 1.5rem;
  text-align: inherit;
  white-space: nowrap;
  width: 100%;
}
.text-success {
  color: #00d97e !important;
}
.update-link_prop{
  text-decoration: none;
  color: #6e84a3;
  line-height: 1.8rem;
  padding: 0rem 5.5rem 0rem 1.5rem;

}
.update-link_prop:hover{
  text-decoration: none;
  color: #000000;
}
.edit-link{
  cursor: pointer;
  line-height: 1.8rem;
  padding: 0rem 1.5rem;
}
.update-link {
  cursor: pointer;
}
.delete-link {
  color: #e63757;
  cursor: pointer;
}
.btn-pagination {
  border-radius: 6px;
  padding-left: 0;
  padding-right: 0;
  width: 1.7rem;
  height: 1.7rem;
  background-color: #2BC38C;
  border-color: #2BC38C;
  color: #fff;
}
.btn-round {
  background: linear-gradient(180deg, #00b17a 0%, #27A97A 100%);
  border-color: #00b17a;
  color: #fff;
  border-radius: 6px;
  min-height: 30px;
  align-items: center;
  padding-right: 2px;
  padding-left: 2px;
  font-weight: 300;
  font-size: 0.6rem;
  min-width: 20px;
}
.card-fill{
  background: #FFFFFF;
  border: 1px solid #E3EBF6;
  border-radius: 10px;
  height: calc(100vh - 45vh);
}
.btn-link{
  color: #2BC38C;
  text-decoration: none;
}

/* BILLING ACCOUNT  */
.card-counter{
  margin: 5px;
  padding: 20% 20%;
  background-color: #fff;
  height: 190px;
  border-radius: 0.5rem;
  transition: .3s linear all;
  text-align: center;

}
.card-counter:hover{
  box-shadow: 4px 4px 20px #DADADA;
  transition: .3s linear all;
}
.card-counter p{
  font-size: 17 px;
  color: #BCBCBC;
}
.card-counter h3{
  font-size: 20px;
  color: #0F1F26;
}
.card-Main{
  margin: 5px;
  padding: 20px 20px;
  background-color: #fff;
  height: 190px;
  border-radius: 0.5rem;
  transition: .3s linear all;
  text-align: left;
}
.card-Main:hover{
  box-shadow: 4px 4px 20px #DADADA;
  transition: .3s linear all;
}
.card_Main_initial{
  display: flex;
  justify-content: space-between;
  padding-bottom: 2px;
}
.card-Main p{
  font-size: 12px;
  color: #BCBCBC;
}
.card-Main h5{
  font-size: 15px;
  color: #0F1F26;
}
.card-Main h6{
  color: #0F1F26;
  font-size: 12px;
}
.card-billingA{
  margin: 5px;
  padding: 20px;
  background-color: #fff;
  height: 139px;
  border-radius: 5px;
  transition: .3s linear all;
  text-align: left;
}
.card-billingA:hover{
  box-shadow: 4px 4px 20px #DADADA;
  transition: .3s linear all;
}
.card-billingA p{
  font-weight: 400;
  font-size: 12px;
  color: #2BC38C;
}
.card-billingA h5{
  font-size: 20px;
  color: #0F1F26;
  font-weight: 700;
}

/* BILLING SUBSCRIPTION  */
.card_title_icon{
  background-color: #2BC38C;
  border-radius: 50%;
  padding: 15px;
  margin-right: 10px;
}
.card_title_bold{
  font-weight: 600;
  font-size: 31.0909px;
  color: #0B212D;
}
.card_title{
  display: flex;
  padding:10px 0 0 10px;
}
.card_title h2{
  font-size: 25px;
}
.Bsubscribe{
  max-width: 171px;
  margin: 0px 15px;
}
.Subscription_Card{
 display: flex;
 justify-content: flex-start;
 text-align: left; 
 padding: 10px;
 background: #F3F3F3;
 color: #828282;
 /* width: 240.09px; */
 border: none;
}
.card_description{
  padding: 10px;
}

/* SEARCH BAR CSS */
.has-search .form-control {
  padding-left: 2.375rem;
}
.has-search .form-control-feedback {
  position: absolute;
  z-index: 2;
  display: block;
  width: 2.375rem;
  height: 2.375rem;
  line-height: 3rem;
  text-align: center;
  pointer-events: none;
  color: #aaa;
  padding: 5px;

}
.input-group .form-control {
  appearance: auto;
  background-color: transparent;
  border: none !important;
  border-radius: 0.375rem;
  color: #BCBCBC;
}
.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
  color: #BCBCBC;
  border: 1px solid #E7E7E7;
  border-radius: 0.375rem;
}
.search-panel{
  border-left: 1px solid #E7E7E7 !important;
  margin: 2px;
  border:none;
  width:70px;
}
.search-panel_options{
  color: #E7E7E7;
  padding: 8px 1px;
  border: none;
  cursor: pointer;
  background-color: transparent;
  width:70px;

}
.search-panel_options:hover{
  padding: none;
  border:none !important;
}
#search_concept{
  color: #BCBCBC;
}
.search-panel:hover{
  padding: none;
}
.pull-right {
  color: #0F1F26;
  padding: 8px 0px 8px 16px;
  border: 1px solid transparent;
  border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
  cursor: pointer;
}
.pull-right:hover{
  color: #BCBCBC;
}
.rs-picker-toggle.rs-btn-lg {
  padding: 7px 16px !important;
}

/* Off-Canvas */
.offcanvas.offcanvas-end{
  width: 571px;
  z-index: 5000;
}
.offcanvas-title {
  font-weight: 700;
  font-size: 24px;
  color: #12263F;
}
.offcanvas-header .btn-close {
  font-size: 15px;
  margin: 1%;
  position: 1%;
}
/* SETTINGS COMPANY INFORMATION */
.fl-sm {
  position: relative;
  top: -6px;
  font-style: italic;
}
.text-muted {
  color: #95aac9 !important;
}
.form-label {
  font-size: 0.8rem;
  margin-bottom: 0.5rem;
  color: #0F1F26;
  font-weight: 300;
}
.form-title {
  display: flex;
  margin-top: 0.5rem;
  margin-bottom: 1.1rem;
  color: #0F1F26;
}

#label-file-upload {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 2px;
  border-radius: 6px;
  box-sizing: border-box;
  border: 1px dashed #BCBCBC;
  background-color:  #ffffff;
  margin-bottom: 24px !important;
  Width: 464px;
  Height: 209px;
}

#label-file-upload.drag-active {
  background-color:#f8fafc;
}
/* .dropzone {
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 6px;
  border: 1px dashed #BCBCBC;
  box-sizing: border-box;
  margin-bottom: 24px !important;
  Width: 464px;
  Height: 209px;
} */

.upload-button:hover {
  text-decoration-line: underline;
}

#drag-file-element {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}
.upload-content {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #BCBCBC;
  font-size: 0.9rem;
  font-weight: 300;
}
.upload-content .bi {
  color: #2BC38C;
  font-size: 2rem;
  margin-bottom: 12px;
}
.upload-avatar .bi {
  color: #2BC38C;
  font-size: 1rem;
  margin-right: 12px;
}
.avatar_border{
  border: 1.5px solid #DFE0EB;
  /* border-radius: 50%; */
  width: 88px;
  height: 88px;
}
.Company-avatar{
  /* border-radius: 50%; */
  padding: 5px;
  width: 88px;
  height: 88px;
}
.detail-section {
  padding: 18px;
  border: 1px solid #E7E7E7;
  border-radius: 7px;
  font-weight: 300;
}
.detail-title {
  margin-top: 7px;
  margin-bottom: 12px;
}
.detail-contents .col-md-4 {
  margin-bottom: 1.5rem !important;
}
.dc-title {
  display: flex;
  font-size: .8rem;
  color: #0F1F26;
  margin-bottom: 3px;
}
.dc-body {
  font-size: .8rem;
  color: #BCBCBC;
  display: flex;
}
.dc-body_file {
  display: flex;
  padding-top: 12px;
}
.dc-body_file .file-thumb {
  margin-right: 12px;
  /* min-height: 80px; */
}
.dc-body_file .file-thumb img {
  height: 50px;
}
.dc-body_file .dc-file-details-title {
  color: #1F2D3D;
  font-weight: bold;
  position: relative;
  margin-bottom: 1rem;
}
.dc-body_file .dc-file-details-size {
  color: #BCBCBC;
  font-weight: normal;
  position: relative;
  top: -10px;
  display: flex;
}
.card-header{
  display: flex;
}
.state {
  font-size: 0.8rem;
  color: #1F2D3D;
  font-weight: 300;
}
.state::before {
  content: " ";
  display: inline-block;
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background-color: #46E7AD;
  margin-right: 5px;
}
.InvoiceHeader th{
  background: #2BC38C !important;
  color: #FFFFFF !important;
  text-align: end !important;
}
.Invoiceamount{
  text-align: end !important;
}
.paymentMethodImg{
  width: 36px;
  height: 15px;
  padding-right: 5px;
}
.border-top{
  border-top: 2px solid #C3C3C3;
}
.money{
  font-size: 20px;
  color: #2BC38C !important;
  font-weight: 300;
}
.invoice-header{
  color: #000000;
}
.invoice-head p{
  color: #828282;
}
.invoice-info{
  color: #828282;
}
.page {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#form-file-upload {
  height: 16rem;
  width: 28rem;
  max-width: 100%;
  text-align: center;
  position: relative;
}

#input-file-upload {
  display: none;
}

/* Add Subscription Group */
.Application{
background-color: #ffffff;
margin: 15px 0px;
padding-top: 5px;

}
.allissues{
background-color: #ffffff;
}
.selectAll .legend label {
  margin-left: 2px; 
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  padding: 5px;
  color: #46E7AD;
}
.issues .legend input { 
  margin-right: .25em; 
}

.issues .legend label { 
  margin-left: 2px; 
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  padding: 5px;

} 
.issues .legend:hover, 
.issues .legend label.focus  { 
  color: #0F1F26!important;
  font-weight: 600;
  font-size: 15px;

} 

/* STAFF PAGINATION */
.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #00b17a!important;
  border-color: #00b17a!important;
}
.pagination{
  --bs-link-color:#00b17a;
  --bs-pagination-color: var(--bs-link-color);
  --bs-link-hover-color: var(--bs-link-color);
  --bs-pagination-focus-color: var(--bs-link-hover-color);
  --bs-pagination-hover-color: var(--bs-link-hover-color);
  --bs-pagination-active-bg: #00b17a !important;
  --bs-pagination-active-border-color: #00b17a !important;

}

/* CHECKBOX INPUT CHECKED */

input[type=checkbox] {
  width: 14px;
  height: 14px;
  accent-color:  #2BC38C;
}
